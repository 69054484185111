import qs from "qs"; // Utilisez `qs` pour une sérialisation avancée (facultatif)
import { Focus } from "../types";
import axiosInstance from "./axiosInstance"; // Import the configured axiosInstance

// Function to get all focuses
export const getAllFocuses = async (): Promise<Focus[]> => {
  try {
    const response = await axiosInstance.get<Focus[]>("/focus");
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.detail || "Failed to fetch focuses");
    } else {
      throw new Error("Failed to fetch focuses");
    }
  }
};

// Function to get all focuses by the current user
export const getAllFocusesByUser = async (): Promise<Focus[]> => {
  try {
    const response = await axiosInstance.get<Focus[]>("/user/focuses");
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to fetch user focuses",
      );
    } else {
      throw new Error("Failed to fetch user focuses");
    }
  }
};

export const getFocusByID = async (focus_id: string): Promise<Focus> => {
  try {
    const response = await axiosInstance.get<Focus>(`/focus/${focus_id}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.detail || "Failed to fetch focus");
    } else {
      throw new Error("Failed to fetch focus");
    }
  }
};

// Function to add a focus to the current user
export const addFocusToUser = async (focus_id: string) => {
  try {
    const response = await axiosInstance.post("/focus/add_to_user", {
      focus_id, // Sending focus_id in the request body as JSON
    });

    return response.data; // Return the response data if needed
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to add focus to user",
      );
    } else {
      throw new Error("Failed to add focus to user");
    }
  }
};

export const createFocus = async (focusData: { name: string }) => {
  try {
    const response = await axiosInstance.post("/focus", focusData);
    return response.data; // Return the created focus data
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.detail || "Failed to create focus");
    } else {
      throw new Error("Failed to create focus");
    }
  }
};

export const getNumberOfFocuses = async (): Promise<number> => {
  try {
    const response = await axiosInstance.get<number>("/user/nb_focuses");
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to fetch number of focuses",
      );
    } else {
      throw new Error("Failed to fetch number of focuses");
    }
  }
};

export const getNumberOfMentions = async (
  focusIds?: string[],
): Promise<number> => {
  try {
    const response = await axiosInstance.get<number>("/focus/nb_mentions", {
      params: { focus_ids: focusIds },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" }); // focus_ids=uuid1&focus_ids=uuid2
      },
    });

    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to fetch number of mentions",
      );
    } else {
      throw new Error("Failed to fetch number of mentions");
    }
  }
};
