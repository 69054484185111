import {
  IconBrandAmazon,
  IconBrandInstagram,
  IconBrandTiktok,
  IconNews,
  IconTool,
} from "@tabler/icons-react";
import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { SocialMedia } from "../types";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const createSlug = (name: string) => {
  return name
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "");
};

export const typeWriter = (
  content: string[],
  textPosition: number,
  speed: number,
  element: HTMLElement,
) => {
  if (element) {
    element.innerHTML =
      content[0].substring(0, textPosition) + "<span id='blinker'></span>";

    if (textPosition++ < content[0].length) {
      setTimeout(
        () => typeWriter(content, textPosition, speed, element),
        speed,
      );
    } else {
      const blinker = document.getElementById("blinker");
      if (blinker) {
        blinker.remove();
      }
      // Appeler le callback une fois l'effet de frappe terminé
    }
  }
};

export function highlightText(text: string, searchTerm: string): string {
  if (!searchTerm.trim()) return text; // If no search term, return the original text

  // Create a regular expression to find the search term (case-insensitive)
  const regex = new RegExp(`(${searchTerm})`, "gi");

  // Replace the matched search term with a span that has the highlight class
  return text.replace(regex, '<span class="highlight">$1</span>');
}

export function formatDateForChat(timestamp: string): string {
  const date = new Date(timestamp); // Date en UTC
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // Obtenir le fuseau horaire local de l'utilisateur
  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Force l'utilisation du fuseau horaire local de l'utilisateur
  };

  // Comparer les dates sans l'heure pour vérifier si c'est aujourd'hui ou hier
  if (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  ) {
    // Aujourd'hui
    return `Aujourd'hui à ${date.toLocaleTimeString([], options)}`;
  } else if (
    date.getFullYear() === yesterday.getFullYear() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getDate() === yesterday.getDate()
  ) {
    // Hier
    return `Hier à ${date.toLocaleTimeString([], options)}`;
  } else {
    // Autre jour de la semaine
    return `${date.toLocaleDateString([], {
      weekday: "long", // Affiche le jour de la semaine
    })} à ${date.toLocaleTimeString([], options)}`;
  }
}

export const getSocialMediaName = (id: string, socialMedias: SocialMedia[]) => {
  const socialMedia = socialMedias.find((sm) => sm.social_media_id === id);
  return socialMedia ? socialMedia.name : id; // Si le nom est trouvé, le retourner, sinon retourner l'ID
};

export const getSocialMediaIcon = (id: string, socialMedias: SocialMedia[]) => {
  const socialMedia = socialMedias.find((sm) => sm.social_media_id === id);
  if (!socialMedia) return IconTool; // Si le réseau social n'est pas trouvé, retourner l'icône par défaut

  if (socialMedia.formatted_name === "tiktok") {
    return IconBrandTiktok;
  } else if (socialMedia.formatted_name === "instagram") {
    return IconBrandInstagram;
  } else if (socialMedia.formatted_name === "google_news") {
    return IconNews;
  } else if (socialMedia.formatted_name === "amazon_reviews") {
    return IconBrandAmazon;
  } else {
    return IconTool;
  }
};

// Vérifie si une URL est externe
export function isExternalUrl(url: string): boolean {
  return /^(https?:\/\/)/.test(url);
}
