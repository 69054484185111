import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import GoogleCallbackPage from "./components/auth/GoogleCallbackPage";
import Login from "./components/auth/Login";
import PasswordReset from "./components/auth/PasswordReset";
import ResetPasswordRequest from "./components/auth/ResetPasswordRequest";
import SignUp from "./components/auth/SignUp";
import ChatPage from "./components/Chat";
import Configuration from "./components/Configuration";
import Dashboard from "./components/Dashboard";
import FocusDetail from "./components/FocusDetail";
import Focuses from "./components/Focuses";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import Profile from "./components/Profile";
import { UserProvider } from "./context/UserContext";

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <UserProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/focus" replace />} />
            <Route element={<Layout />}>
              <Route element={<PrivateRoute />}>
                <Route path="focus/:id" element={<FocusDetail />} />{" "}
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="chat" element={<ChatPage />} />
                <Route path="focus" element={<Focuses />} />
                <Route path="configuration" element={<Configuration />} />
                <Route path="profile" element={<Profile />} />
              </Route>
            </Route>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/signup" element={<SignUp />} />
            <Route
              path="/auth/google/callback"
              element={<GoogleCallbackPage />}
            />
            <Route
              path="/auth/reset-password-request"
              element={<ResetPasswordRequest />}
            />
            <Route path="/auth/reset-password" element={<PasswordReset />} />
          </Routes>
        </UserProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
