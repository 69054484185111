import { ChevronRight, LoaderCircle, PlusIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllFocusesByUser } from "../lib/focus";
import { cn } from "../lib/utils";
import { Focus } from "../types";
import Header from "./Header";
import { Button } from "./ui/button";

const Focuses: React.FC = () => {
  const [myFocuses, setMyFocuses] = useState<Focus[]>([]);

  const [filteredFocuses, setFilteredFocuses] = useState<Focus[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    setIsLoading(true);

    const loadFocuses = async () => {
      try {
        const myFocusesData = await getAllFocusesByUser();
        setMyFocuses(myFocusesData);
      } catch (err: any) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadFocuses();
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredFocuses(myFocuses);
    } else {
      const filtered = myFocuses.filter((focus) =>
        focus.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setFilteredFocuses(filtered);
    }
  }, [searchQuery, myFocuses]);

  const hoverColors = [
    "hover:bg-green-light",
    "hover:bg-orange-light",
    "hover:bg-purple-light",
  ];

  const buttonColors = ["bg-green", "bg-orange", "bg-purple"];

  // Return both hover and base colors as a string
  const getColorClass = (index: number) => {
    const hoverColor = hoverColors[index % hoverColors.length];
    const buttonColor = buttonColors[index % buttonColors.length];
    return { hoverColor, buttonColor };
  };

  return (
    <>
      <Header
        title="Focus"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />

      <div className="px-4 pb-10 sm:px-8 lg:px-12">
        <a href="/configuration">
          <Button className="mb-8 ml-auto flex w-full rounded-lg px-12 font-normal sm:w-fit">
            <PlusIcon size={20} className="mr-2" />
            Nouveau focus
          </Button>
        </a>

        {isLoading && (
          <LoaderCircle className="mx-auto size-16 animate-spin text-blue" />
        )}

        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {filteredFocuses
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((focus, index) => {
              const colorClasses = getColorClass(index); // Get hover and base color
              return (
                <Link
                  to={`/focus/${focus.focus_id}`}
                  key={focus.focus_id}
                  className={cn(
                    "col-span-1 flex h-36 flex-col justify-between rounded-3xl p-4 shadow-full transition-all duration-300",
                    "bg-white", // Default base color
                    colorClasses.hoverColor, // Hover and button color
                  )}
                >
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-light">
                      {new Date(focus.created_at).toLocaleDateString()}
                    </p>
                    <h1 className="text-lg font-bold leading-5">
                      {focus.name}
                    </h1>
                  </div>
                  <div
                    className={cn(
                      "w-fit rounded-full p-2 text-white shadow-none",
                      colorClasses.buttonColor,
                    )}
                  >
                    <ChevronRight size={20} />
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Focuses;
