export const URLS = {
  STATIC_IMAGE: {
    PROD: "https://moodee.app/backend-static/images/",
    DEV: "http://127.0.0.1:8000/backend-static/images/",
    get CURRENT() {
      return process.env.NODE_ENV === "development" ? this.DEV : this.PROD;
    },
  },
  API: {
    PROD: "https://moodee.app/api/v1",
    DEV: "http://127.0.0.1:8000/api/v1",
    get CURRENT() {
      return process.env.NODE_ENV === "development" ? this.DEV : this.PROD;
    },
  },
};
