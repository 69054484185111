import { FileUp } from "lucide-react";
import React, { useState } from "react";
import { useUser } from "../context/UserContext";
import { requestPasswordReset, updateUserInfo } from "../lib/auth"; // Import de la fonction pour mettre à jour l'utilisateur
import AvatarUser from "./Avatar";
import Header from "./Header";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Input } from "./ui/input";

const Profile: React.FC = () => {
  const { user } = useUser(); // Assurez-vous que le contexte peut mettre à jour l'utilisateur
  const [newFirstName, setNewFirstName] = useState<string | undefined>(
    user?.first_name,
  );
  const [newLastName, setNewLastName] = useState<string | undefined>(
    user?.last_name,
  );
  const [newEmail, setNewEmail] = useState<string | undefined>(user?.email);
  const [profileImage, setProfileImage] = useState<File | undefined>(undefined); // Fichier image
  const [previewImage, setPreviewImage] = useState<string | undefined>(""); // Aperçu de l'image
  const [previewImageName, setpreviewImageName] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setPreviewImage(reader.result as string);
          setpreviewImageName(file.name);
          setProfileImage(file);
        }
      };
      reader.readAsDataURL(file);
    } else {
      alert("Veuillez sélectionner une image au format JPG ou PNG.");
    }
  };

  const handleSaveProfile = async () => {
    const formData = new FormData();

    if (newEmail) formData.append("email", newEmail.trim());
    if (newFirstName) formData.append("first_name", newFirstName.trim());
    if (newLastName) formData.append("last_name", newLastName.trim());
    if (profileImage) formData.append("profile_picture", profileImage);

    try {
      await updateUserInfo(formData); // Envoie directement le FormData
      window.location.reload();
    } catch (error: any) {
      console.error("Erreur lors de la mise à jour du profil :", error);
      alert(error.message || "Échec de la mise à jour du profil.");
    }
  };

  const handleCancel = () => {
    setPreviewImage(undefined);
    setProfileImage(undefined);
    setpreviewImageName(null);
    setNewEmail(user?.email);
    setNewFirstName(user?.first_name);
    setNewLastName(user?.last_name);
  };

  const handleResetPassword = async () => {
    if (!user) return;
    try {
      await requestPasswordReset(user?.email);
    } catch (err: any) {
      console.error("Password reset request failed:", err);
    }
  };

  return (
    <div>
      <Header title="Profil" />
      <div className="relative">
        <img
          src="/moodee-bg-profile.png"
          alt="background-profile"
          className="relative z-10 h-64 w-full object-cover"
        />
        <AvatarUser
          user={user}
          className="absolute z-50 -mt-16 ml-8 size-32 border-[8px] border-white border-opacity-100 sm:-mt-20 sm:size-40"
          fontSize="text-3xl"
        />
      </div>

      <div className="px-10">
        <div className="mt-20 grid gap-2 sm:mt-10 md:grid-cols-4">
          <div className="col-span-full mb-4 flex flex-col gap-2 sm:ml-auto sm:flex-row">
            <Button
              className="w-full rounded-lg border border-gray-300 bg-transparent text-dark hover:bg-transparent"
              onClick={handleCancel}
            >
              Annuler
            </Button>
            <Button className="w-full rounded-lg" onClick={handleSaveProfile}>
              Enregistrer
            </Button>
          </div>
          <div className="md:max-w-96">
            <h3 className="font-bold">Informations personnelles</h3>
            <p className="text-sm text-gray-500">
              Vous pouvez modifier vos informations personnelles ici
            </p>
          </div>
          <div className="grid gap-5 rounded-3xl border border-blue p-5 sm:grid-cols-2 md:col-span-3">
            <div>
              <label htmlFor="firstName" className="text-sm font-bold">
                Prénom
              </label>
              <Input
                type="text"
                id="firstName"
                value={newFirstName}
                placeholder="Prénom"
                onChange={(e) => setNewFirstName(e.target.value)}
                required
                className="h-10 w-full"
              />
            </div>

            <div>
              <label htmlFor="lastName" className="text-sm font-bold">
                Nom
              </label>
              <Input
                type="text"
                id="lastName"
                value={newLastName}
                placeholder="Nom"
                onChange={(e) => setNewLastName(e.target.value)}
                required
                className="h-10 w-full"
              />
            </div>

            <div>
              <label htmlFor="email" className="text-sm font-bold">
                Email
              </label>
              <Input
                type="text"
                id="email"
                value={newEmail}
                placeholder="Email"
                onChange={(e) => setNewEmail(e.target.value)}
                required
                className="h-10 w-full"
              />
            </div>

            <div>
              <label htmlFor="startDate" className="text-sm font-bold">
                Mot de passe
              </label>

              <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
                <DialogTrigger className="flex h-10 w-full items-center justify-center rounded-full bg-blue text-sm text-white transition-colors hover:bg-blue/90">
                  Réinitialiser le mot de passe
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>
                      Confirmer la réinitialisation du mot de passe
                    </DialogTitle>
                    <DialogDescription>
                      Êtes-vous sûr de vouloir réinitialiser votre mot de passe
                      ? Un email contenant un lien pour définir un nouveau mot
                      de passe sera envoyé à votre adresse email enregistrée.
                    </DialogDescription>
                  </DialogHeader>
                  <div className="flex justify-end gap-4">
                    <Button
                      className="rounded-lg border border-gray-300 bg-transparent text-dark hover:bg-transparent"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Annuler
                    </Button>
                    <Button
                      onClick={handleResetPassword}
                      className="rounded-lg bg-green hover:bg-green/90"
                    >
                      Confirmer
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
              <div></div>
            </div>

            <div className="col-span-full">
              <label htmlFor="fileInput" className="text-sm font-bold">
                Photo de profil
              </label>
              <div className="flex flex-col items-center gap-4 sm:flex-row sm:items-start sm:gap-10">
                {previewImage ? (
                  <img
                    src={previewImage || ""}
                    alt="User Profile"
                    className="size-16 rounded-full"
                  />
                ) : (
                  <AvatarUser user={user} className="size-16" />
                )}

                <div
                  className="flex w-full flex-1 cursor-pointer flex-col items-center justify-center rounded-2xl border border-dashed border-blue px-6 py-6"
                  onClick={() => document.getElementById("fileInput")?.click()}
                >
                  <div className="rounded-full bg-blue-light p-3">
                    <FileUp className="size-6 text-blue" strokeWidth={3} />
                  </div>
                  {previewImage ? (
                    <>
                      <p className="mb-4">
                        <span className="text-blue">Photo importée</span>{" "}
                        {previewImageName}
                      </p>
                    </>
                  ) : (
                    <p>
                      <span className="text-blue">Cliquez ici</span> pour
                      importer votre photo
                    </p>
                  )}

                  <p className="text-sm text-gray-400">
                    Format supporté : JPG, PNG
                  </p>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/png, image/jpeg"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
