import { Chat, Conversation } from "../types";
import axiosInstance from "./axiosInstance";
import { URLS } from "./constants";

export const startNewChat = async (conversationData: {
  focus_ids: string[];
  social_media_ids: string[];
}): Promise<Conversation> => {
  try {
    const response = await axiosInstance.post(
      "/ask_moodee/new_conversation",
      conversationData,
    );
    return response.data;
  } catch (error: any) {
    // Type assertion
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error creating chat");
    }
  }
};

export const getAllConversationsByUser = async (): Promise<Conversation[]> => {
  try {
    const response = await axiosInstance.get("/ask_moodee/conversations");
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching conversations");
    }
  }
};

export const getConversationHistory = async (
  conversationId: string,
): Promise<Chat[]> => {
  try {
    const response = await axiosInstance.get(
      `/ask_moodee/history`, // Ne pas mettre le conversationId dans le chemin
      { params: { conversation_id: conversationId } }, // Utiliser un paramètre de requête
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching conversation history");
    }
  }
};

export const postFirstMessage = async (
  conversation_id: string,
): Promise<Chat> => {
  try {
    const response = await axiosInstance.post(
      "/ask_moodee/first_message",
      { conversation_id }, // Envoi d'un objet contenant conversation_id
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching first message");
    }
  }
};

export const askQuestionToMoodee = async (
  conversation_id: string,
  content: string,
  onChunkReceived: (chunk: string) => void, // Callback pour gérer le streaming en temps réel
): Promise<void> => {
  try {
    const response = await fetch(`${URLS.API.CURRENT}/ask_moodee/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Authentification si nécessaire
      },
      body: JSON.stringify({ conversation_id, content }),
    });

    // Vérifie si la réponse est OK
    if (!response.ok) {
      throw new Error("Erreur lors du fetch en streaming");
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();

    if (reader) {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });

        // Appelle le callback à chaque réception de chunk
        onChunkReceived(chunk);
      }
    }
  } catch (error: any) {
    throw new Error(error.message || "Erreur lors de la requête en streaming");
  }
};

export const deleteConversation = async (conversationId: string) => {
  try {
    const response = await axiosInstance.delete(
      `/ask_moodee/delete_conversation`, // Ne pas mettre le conversationId dans le chemin
      { params: { conversation_id: conversationId } }, // Utiliser un paramètre de requête
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error deleting  history");
    }
  }
};
