import {
  ChartNoAxesColumnIncreasing,
  LoaderCircle,
  SquareChartGantt,
} from "lucide-react";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useUser } from "../context/UserContext";
import { getListAppreciatedFocuses } from "../lib/analysis";
import { getNumberOfFocuses, getNumberOfMentions } from "../lib/focus";
import { FocusWithSentimentScore } from "../types";
import Header from "./Header";
import BarChart from "./charts/bar-chart";

const Dashboard: React.FC = () => {
  const { user } = useUser();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const {
    data: numberOfFocuses,
    isLoading: numberOfFocusesLoading,
    error: numberOfFocusesError,
  } = useQuery<number>(["numberOfFocuses"], () => getNumberOfFocuses(), {
    staleTime: 60000,
  });

  const {
    data: numberOfMentions,
    isLoading: numberOfMentionsLoading,
    error: numberOfMentionsError,
  } = useQuery<number>(["numberOfMentions"], () => getNumberOfMentions(), {
    staleTime: 60000,
  });

  const {
    data: listAppreciatedFocuses,
    isLoading: listAppreciatedFocusesLoading,
    error: listAppreciatedFocusesError,
  } = useQuery<FocusWithSentimentScore[]>(
    ["listAppreciatedFocuses"],
    () => getListAppreciatedFocuses(),
    {
      staleTime: 60000,
    },
  );

  return (
    <>
      <div className="w-screen rounded-5xl rounded-tr-none bg-blue text-white">
        <Header
          title="Dashboard"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          isDashboard={true}
        />

        <div className="w-full px-4 pb-10 sm:px-8 lg:px-12">
          <h2 className="mb-1 text-xl font-bold">
            Bonjour {user?.first_name},
          </h2>
          <p className="mb-6 text-sm font-light">
            Retrouve ci-dessous les chiffres clés de tes produits
          </p>

          <div className="flex gap-10 text-dark">
            <div className="flex w-52 flex-col justify-between gap-3 rounded-3xl bg-green-light px-6 py-4">
              <div className="size-fit rounded-full bg-green p-3">
                <ChartNoAxesColumnIncreasing
                  size={24}
                  className="rounded-md bg-white p-0.5 text-green"
                />
              </div>
              <div>
                {numberOfFocusesLoading ? (
                  <LoaderCircle className="mx-auto size-12 animate-spin text-green" />
                ) : numberOfFocusesError ? (
                  <p>Erreur</p>
                ) : (
                  <p className="text-xl font-bold">{numberOfFocuses}</p>
                )}
                <p className="text-sm font-normal"> Total de tes produits</p>
              </div>
            </div>
            <div className="flex w-52 flex-col justify-between gap-3 rounded-3xl bg-orange-light px-6 py-4">
              <div className="size-fit rounded-full bg-orange p-3">
                <SquareChartGantt size={24} className="text-white" />
              </div>
              <div>
                {numberOfMentionsLoading ? (
                  <LoaderCircle className="mx-auto size-12 animate-spin text-green" />
                ) : numberOfMentionsError ? (
                  <p>Erreur</p>
                ) : (
                  <p className="text-xl font-bold">{numberOfMentions}</p>
                )}
                <p className="text-sm font-normal">Total des mentions</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-4 py-8 sm:px-8 lg:px-12">
        <h2 className="m-2 text-2xl font-bold text-green">
          Popularité de tes produits
        </h2>
        <div className="max-w-fit rounded-3xl border border-green px-2 py-4 sm:min-w-96">
          {listAppreciatedFocusesLoading ? (
            <LoaderCircle className="mx-auto my-10 size-16 animate-spin text-green" />
          ) : listAppreciatedFocusesError ? (
            <p>Erreur lors du chargement des données</p>
          ) : (
            listAppreciatedFocuses && <BarChart data={listAppreciatedFocuses} />
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
